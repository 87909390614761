export const employeeRoles = [
  { label: "Manager", value: "M" },
  {
    label: "Sub Admin",
    value: "AD",
  },
  {
    label: "Community Manager",
    value: "CM",
  },
];

//ignore permission condition for this pages
export const Route_Ignore_Permission_Conditions = [
  "/offer/:id",
  "/skill/:id",
  "/transport/:id",
  "/chat-session/:id",
  "/voucher/:id",
  "/cancellation-request/:id",
  "/certificate/:id",
  "/portfolio/:id",
  "/reset-password",
  "/addUser"
];

// role permissions array
export const Route_Permission_Options = [
  {
    path: "/user",
    title: "Users Listing",
    isAccess: false,
    subPermission: [
      {
        functionalityName: "export",
        description: "export user",
        label: "Export", // Same as Label on page
        isAccess: false,
      },
      {
        functionalityName: "delete",
        description: "delete user from user table",
        label: "Delete", // Same as Label on page
        isAccess: false,
      },
      {
        functionalityName: "Send Notification",
        description: "send notification functionality",
        label: "Send Notification", // Same as Label on page
        isAccess: false,
      },
    ],
  },

  {
    path: "/user/:id",
    title: "Users Details",
    isAccess: false,
    subPermission: [
      {
        functionalityName: "selfie",
        description: "selfie field",
        label: "Selfie",
        isAccess: false,
      },
      {
        functionalityName: "document",
        description: "document user field",
        label: "Document",
        isAccess: false,
      },
      {
        functionalityName: "Notifications",
        description: "Permission for Notification tab",
        label: "Notifications",
        isAccess: false,
      },
      {
        functionalityName: "Edit Email",
        description: "Edit email functionality",
        label: "Edit Email",
        isAccess: false,
      },
      {
        functionalityName: "Edit Phone",
        description: "Edit phone functionality",
        label: "Edit Phone",
        isAccess: false,
      },
      {
        functionalityName: "Update Pin",
        description: "Update Pin functionality",
        label: "Update Pin",
        isAccess: false,
      },
    ],
  },
  {
    path: "/employees",
    title: "Employees",
    isAccess: false,
    subPermission: [],
  },
  {
    path: "/roles",
    title: "Roles",
    isAccess: false,
    subPermission: [],
  },
  {
    path: "/partners",
    title: "Partners",
    isAccess: false,
    subPermission: [],
  },
  {
    path: "/plans",
    title: "Plans",
    isAccess: false,
    subPermission: [],
  },
  {
    path: "/content",
    title: "Content",
    isAccess: false,
    subPermission: [],
  },
  {
    path: "/category",
    title: "Category",
    isAccess: false,
    subPermission: [],
  },
  {
    path: "/requests",
    title: "Requests",
    isAccess: false,
    subPermission: [
      {
        functionalityName: "user delete requests",
        description: "Delete User Requests functionality",
        label: "User Delete Requests",
        isAccess: false,
      },
      {
        functionalityName: "certificates",
        description: "certificates requests",
        label: "Certificates",
        isAccess: false,
      },
      {
        functionalityName: "portfolio",
        description: "portfolio requests",
        label: "Portfolioes",
        isAccess: false,
      },
      {
        functionalityName: "payout requests",
        description: "portfolio requests functionality",
        label: "Payout Requests",
        isAccess: false,
      },
      {
        functionalityName: "kyc outdated users",
        description: "kyc outdated users functionality",
        label: "Kyc Outdated Users",
        isAccess: false,
      },
      {
        functionalityName: "Task Cancellation Requests",
        description: "Task Cancellation Requests functionality",
        label: "Task Cancellation Request",
        isAccess: false,
      }
    ],
  },
  {
    path: "/portfolios",
    title: "Portfolio",
    isAccess: false,
    subPermission: [],
  },
  {
    path: '/share',
    title: 'Invite Friends',
    isAccess: false,
    subPermission: [],
  },
  {
    path: "/task",
    title: "Tasks",
    isAccess: false,
    subPermission: [
      {
        functionalityName: "export",
        description: "Export Task",
        label: "Export",
        isAccess: false,
      },
      {
        functionalityName: "delete",
        description: "delete task from task table",
        label: "Delete",
        isAccess: false,
      },
    ],
  },
  {
    path: "/invoice",
    title: "Invoice",
    isAccess: false,
    subPermission: [],
  },
  {
    path: "/markerImage",
    title: "Marker Image",
    isAccess: false,
    subPermission: [],
  },
  {
    path: "/transaction",
    title: "Transactions",
    isAccess: false,
    subPermission: [],
  },
  {
    path: "/notification",
    title: "Notifications",
    isAccess: false,
    subPermission: [],
  },

  {
    path: "/vouchers",
    title: "Vouchers",
    isAccess: false,
    subPermission: [],
  },
  {
    path: "/activities",
    title: "Activity",
    isAccess: false,
    subPermission: [],
  },
  {
    path: "/inquiries",
    title: "Inquiries",
    isAccess: false,
    subPermission: [],
  },
  {
    path: "/tracking",
    title: "Tracking",
    isAccess: false,
    subPermission: [],
  },

  {
    path: "/chat-info",
    title: "Chat",
    isAccess: false,
    subPermission: [],
  },
  {
    path: "/settings",
    title: "Master settings",
    isAccess: false,
    subPermission: [],
  },
  {
    path: "/videoSettings",
    title: "Video Settings",
    isAccess: false,
    subPermission: [],
  },

  {
    path: "/reasons",
    title: "Feedback",
    isAccess: false,
    subPermission: [
      {
        functionalityName: "Cancellation Reasons Master (Actions)",
        description:
          "Cancellation Reasons Master (Actions) in Cancellation Reasons table",
        label: "Cancellation Reasons Master (Actions)",
        isAccess: false,
      },
      {
        functionalityName: "Active Task Cancellation Reasons Master(Actions)",
        description:
          "Active Task Cancellation Reasons Master(Actions) in Active Task cancellation Reasons",
        label: "Active Task Cancellation Reasons Master(Actions)",
        isAccess: false,
      },
      {
        functionalityName: "Delete Account Reasons Master (Actions)",
        description:
          "Delete Account Reasons Master (Actions) in Delete Account Reasons",
        label: "Delete Account Reasons Master (Actions)",
        isAccess: false,
      },
    ],
  },
  {
    path: "/ai-assistance",
    title: "AI-Assistance",
    isAccess: false,
    subPermission: [],
  },
];

export const Service_Operational_Days_Options = [
  {
    label: "All Days",
    key: "allDays",
    isSelected: false,
    startDate: "",
    endDate: "",
  },
  {
    label: "Monday",
    key: 1,
    isSelected: false,
    startDate: "",
    endDate: "",
  },
  {
    label: "Tuesday",
    key: 2,
    isSelected: false,
    startDate: "",
    endDate: "",
  },
  {
    label: "Wednesday",
    key: 3,
    isSelected: false,
    startDate: "",
    endDate: "",
  },
  {
    label: "Thursday",
    key: 4,
    isSelected: false,
    startDate: "",
    endDate: "",
  },
  {
    label: "Friday",
    key: 5,
    isSelected: false,
    startDate: "",
    endDate: "",
  },
  {
    label: "Saturday",
    key: 6,
    isSelected: false,
    startDate: "",
    endDate: "",
  },
  {
    label: "Sunday",
    key: 0,
    isSelected: false,
    startDate: "",
    endDate: "",
  },
];
